<template>
  <div>
    <div class="orderInfoHead">
      <el-row>
        <el-col :span="12"> <span>订单管理</span>>手工录入订单 </el-col>
        <el-col
          :span="12"
         
          style="color: #256def; text-align: right"
          ><span  @click="goOrderPage" style="cursor: pointer">返回订单管理</span></el-col
        >
      </el-row>
    </div>
    <div class="searchBox">
      <span>城市</span>
      <el-input
        size="mini"
        style="width: 150px; margin-left: 20px"
        v-model="searchData.city"
      ></el-input>
      <el-input
        size="mini"
        style="width: 300px; margin-left: 50px"
        placeholder="请输入酒店"
        v-model="searchData.hotelName"
      >
        <template slot="prepend">酒店名称</template>
      </el-input>
      <el-button
        size="mini"
        @click="getHotelList"
        type="primary"
        style="margin-left: 50px"
        >搜索</el-button
      >
      <el-button size="mini" type="primary" plain style="margin-left: 30px"
        >清空</el-button
      >
    </div>
    <div class="hotelListBox">
      <div style="height: 40px; line-height: 40px; padding-left: 20px">
        <span>共查询到</span><span style="color: #fb4242">{{ total }}</span
        ><span>条酒店信息</span>
      </div>
      <el-table
        :data="hotelList"
        max-height="250px;"
        ref="tb"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column  width="55"> 
           <template slot-scope="scope">
               <el-rodio v-model="scope.row.isRodia" @change="changeIsRodia(scope.row.isRodia)"></el-rodio>
           </template>
        </el-table-column> -->
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="productManagerName" label="产品经理">
        </el-table-column>
        <el-table-column prop="hotelName" label="酒店名称"> </el-table-column>
        <el-table-column prop="city" label="城市"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
      </el-table>
    </div>
    <div class="hotelListBox">
      <div class="hotelTitle">
        <span>{{ hotelName }}</span>
        <!-- <div style="display: inline-block; width: 1000px">
          <span>{{ hotelName }}</span>
        </div> -->
        <!-- <div
          style="
            display: inline-block;
            width: 160px;
            text-align: right;
            padding-right: 20px;
          "
        >
          <el-button type="text">新增预定</el-button>
          <el-button type="text">删除</el-button>
        </div> -->
      </div>

      <div class="padding20" style="max-height: 300px; overflow: auto">
        <div
          class="roomBox"
          v-for="item in hotelRoomList"
          :key="item.roomTypeId"
        >
          <div class="roomTitle">{{ item.roomTypeName }}</div>
          <el-row :gutter="20">
            <el-col
              class="horseBox"
              :span="6"
              v-for="ctem in item.roomList"
              :key="ctem.roomId"
            >
              <el-checkbox
                @change="changeIsCheck(ctem, item.roomTypeId)"
                v-model="ctem.isChecked"
              ></el-checkbox>
              {{ ctem.roomName }}
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="hotelListBox">
      <div class="hotelTitle" style="background: #f6f8fb">
        <div style="display: inline-block; width: 1000px">
          <span>{{ roomName }}</span>
        </div>
        <div
          style="
            display: inline-block;
            width: 160px;
            text-align: right;
            padding-right: 20px;
          "
        >
          <el-button type="text">新增预定</el-button>
          <el-button type="text">删除</el-button>
        </div>
      </div>

      <div>
        <el-form :model="setData" label-width="110px" style="margin-top: 20px">
          <el-form-item label="客人姓名">
            <el-input
              size="mini"
              style="width: 160px"
              v-model="setData.guestContact"
            ></el-input>
            <span class="formLabel">多个客人请用,隔开</span>
          </el-form-item>
          <el-form-item label="房间数">
            <el-select
              size="mini"
              style="width: 160px"
              @change="getPrice"
              v-model="setData.numberOfUnits"
            >
              <el-option
                v-for="item in numberOfUnitsOption"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span class="formLabel">间</span>
          </el-form-item>
          <el-form-item label="住宿日期">
            <el-date-picker
              v-model="setData.checkIn"
              size="mini"
              style="width: 130px"
              type="date"
              @change="changeCheckIn"
              placeholder="选择日期"
              :clearable="false"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <span class="margin10">至</span>
            <el-date-picker
              v-model="setData.checkOut"
              size="mini"
              @change="changeCheckOut"
              style="width: 130px"
              type="date"
              :clearable="false"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <span class="formLabel">
              <span>共</span>
              <span>{{ setData.totalRoomNights }}</span>
              <span> 晚 </span>
            </span>
          </el-form-item>
          <el-form-item label="每日房价">
            <div
              class="roomPriceBox"
              v-for="(item, index) in setData.dailyRatesJosn"
              :key="index"
            >
              <div class="priceTitle">
                {{ item.date | initDate }}
              </div>
              <div class="priceContent">
                <span>CNY</span>
                <el-input
                  size="mini"
                  @input="getPrice"
                  style="width: 60px; height: 24px; margin-left: 10px"
                  v-model="item.price"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="每日采购价">
            <div
              class="roomPriceBox"
              v-for="(item, index) in setData.purchasePriceJosn"
              :key="index"
            >
              <div class="priceTitle">
                {{ item.date | initDate }}
              </div>
              <div class="priceContent">
                <span>CNY</span>
                <el-input
                  size="mini"
                  @input="getPrice"
                  style="width: 60px; height: 24px; margin-left: 10px"
                  v-model="item.price"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="总金额">
            预付，共计<span class="fontRule">CNY</span
            ><span class="fontRule">{{ setData.totalBeforeTax }}</span>

            <div v-show="showEdit == 'true'" style="display: inline-block">
              <el-input
                size="mini"
                style="
                  width: 60px;
                  height: 24px;
                  margin-left: 10px;
                  margin-right: 10px;
                "
                v-model="setData.totalBeforeTax"
              ></el-input>
              <el-button
                size="mini"
                @click="changgeShowEdit('false')"
                type="primary"
                >保存</el-button
              >
            </div>

            <el-button @click="changgeShowEdit('true')" type="text"
              >修改</el-button
            >
          </el-form-item>
          <el-form-item label="付款方式">
            <el-radio-group size="mini" v-model="setData.payType">
              <el-radio :label="0" border>一单一结</el-radio>
              <el-radio :label="1" border>预付款</el-radio>
              <el-radio :label="2" border>前台付现</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="订单标签">
            <el-radio-group size="mini" v-model="setData.label">
              <el-radio :label="1" border>线下单</el-radio>
              <el-radio :label="2" border>补款单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采购渠道">
            <el-radio-group size="mini" v-model="setData.procurementType">
              <el-radio :label="0" border>酒店</el-radio>
              <el-radio :label="1" border>其他</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="分销渠道">
            <el-radio-group size="mini" v-model="setData.roomVestId">
              <el-radio
                v-for="item in roomVestList"
                :key="item.roomVestId"
                :label="item.roomVestId"
                border
                >{{ item.horseName }}</el-radio >
                <el-radio :label="''">其他</el-radio>
            </el-radio-group>
            <el-input size="mini" style="width:136px;" v-model="setData.thirdOrderNum"></el-input>

          </el-form-item>

          <el-form-item label="预定说明">
            <el-input
              type="textarea"
              style="width: 500px"
              :rows="8"
              v-model.trim="setData.remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传凭证">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-upload "
              @click="openImgDia"
              >上传图片</el-button
            >
            <span v-if="setData.voucherUrl!=''">已选择{{setData.voucherUrl}}</span>
            <img v-if="setData.voucherUrl!=''" :src="setData.voucherUrl" alt="">
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button style="margin-left:110px;" type="primary" size="mini" @click="saveManualOrder">
          保存</el-button
        >
      </div>
    </div>
    <ManualImgUpDia ref="manualimgupdia" @func="getImgUrl"></ManualImgUpDia>
  </div>
</template>

<script>
import { getHotelList_api } from "../../apis/order";
import { getHotelRoomList_api } from "../../apis/order";
import { saveManualOrder_api } from "../../apis/order";
import ManualImgUpDia from "../../components/dialog/order/ManualImgUpDia.vue";
export default {
  components: {
    ManualImgUpDia,
  },
  data() {
    return {
      searchData: {
        city: "",
        hotelName: "",
      },
      hotelList: [],
      total: 0,
      hotelId: "",
      hotelName: "",
      hotelRoomList: [],
      roomName: "",
      roomVestList: [],
      showEdit: false,
      setData: {
        roomVestId: "",
        guestContact: "",
        numberOfUnits: 1,
        checkIn: new Date(),
        checkOut: new Date(new Date().getTime() + 20 * 60 * 60 * 1000),
        totalRoomNights: 1,
        dailyRatesJosn: [{ date: new Date(), price: 0 }],
        purchasePriceJosn: [{ date: new Date(), price: 0 }],
        
        totalBeforeTax: "",
        purchasePrice: "",
        payType: 0,
        label: 1,
        procurementType: 0,
        remarks: "",
        voucherUrl: "",
        hotelSonId: "",
        roomId: "",
        roomTypeId: "",
        thirdOrderNum: "",
      },
      numberOfUnitsOption: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    };
  },
  created() {},
  filters: {
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
  },
  methods: {
    //获取酒店列表
    async getHotelList() {
      let { code, data } = await getHotelList_api(this.searchData);
      if (code == 0) {
        this.hotelList = data;
        this.total = data.length;
      }
    },

    //切换选项
    handleSelectionChange(selection) {
      this.hotelId = selection[0].hotelId;
      this.setData.hotelSonId = selection[0].hotelId;
      this.hotelName = selection[0].hotelName;

      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      }
      this.getHotelRoomList();
    },
    //获取房型
    async getHotelRoomList() {
      let { code, data } = await getHotelRoomList_api({
        hotelId: this.hotelId,
      });
      if (code == 0) {
        data.forEach((item) => {
          item.roomList.forEach((ctem) => {
            this.$set(ctem, "isChecked", "false");
          });
        });
        this.hotelRoomList = data;
      }
    },
    //changeIsCheck切换房间
    changeIsCheck(val, roomTypeId) {
     // console.log(val);
      this.hotelRoomList.forEach((item) => {
        item.roomList.forEach((ctem) => {
          ctem.isChecked = false;
        });
      });
      val.isChecked = true;
      this.roomName = val.roomName;
      this.setData.roomTypeId = roomTypeId;
      this.setData.roomId = val.roomId;
      this.roomVestList = val.horseList;
      this.setData.roomVestId=''
    },
    //切换开始时间
    changeCheckIn(val) {
      if (this.setData.checkOut) {
        let date1 = new Date(val).getTime();
        let date2 = new Date(this.setData.checkOut).getTime();
        this.setData.totalRoomNights = Math.ceil(
          (date2 - date1) / 24 / 60 / 60 / 1000
        );
        this.setData.dailyRatesJosn[0].date = val;

        this.setData.dailyRatesJosn.splice(
          1,
          this.setData.dailyRatesJosn.length - 1
        );
        this.setData.purchasePriceJosn[0].date = val;

        this.setData.purchasePriceJosn.splice(
          1,
          this.setData.purchasePriceJosn.length - 1
        );

        if (this.setData.totalRoomNights > 1) {
          for (let i = 1; i < this.setData.totalRoomNights; i++) {
            this.setData.dailyRatesJosn.push({
              date: new Date(
                new Date(this.setData.dailyRatesJosn[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.dailyRatesJosn[0].price,
            });
            this.setData.purchasePriceJosn.push({
              date: new Date(
                new Date(this.setData.purchasePriceJosn[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.purchasePriceJosn[0].price,
            });
          }
        }
        this.getPrice();
      }
    },
    //切换结束时间
    changeCheckOut(val) {
      if (this.setData.checkIn) {
        let date2 = new Date(val).getTime();
        let date1 = new Date(this.setData.checkIn).getTime();
        this.setData.totalRoomNights = Math.ceil(
          (date2 - date1) / 24 / 60 / 60 / 1000
        );
        this.setData.dailyRatesJosn[0].date = this.setData.checkIn;
        this.setData.dailyRatesJosn.splice(
          1,
          this.setData.dailyRatesJosn.length - 1
        );

        this.setData.purchasePriceJosn[0].date = this.setData.checkIn;
        this.setData.purchasePriceJosn.splice(
          1,
          this.setData.purchasePriceJosn.length - 1
        );

        if (this.setData.totalRoomNights > 1) {
          for (let i = 1; i < this.setData.totalRoomNights; i++) {
            this.setData.dailyRatesJosn.push({
              date: new Date(
                new Date(this.setData.dailyRatesJosn[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.dailyRatesJosn[0].price,
            });
            this.setData.purchasePriceJosn.push({
              date: new Date(
                new Date(this.setData.purchasePriceJosn[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.purchasePriceJosn[0].price,
            });
          }
        }
        this.getPrice();
      }
    },
    //计算总价格和总采购价
    getPrice() {
      this.setData.totalBeforeTax = 0;
      this.setData.purchasePrice = 0;
      this.setData.dailyRatesJosn.forEach((item) => {
        this.setData.totalBeforeTax =
          Number(this.setData.totalBeforeTax) + Number(item.price);
      });

      this.setData.purchasePriceJosn.forEach((item) => {
        this.setData.purchasePrice =
          Number(this.setData.purchasePrice) + Number(item.price);
      });
      this.setData.totalBeforeTax =
        Number(this.setData.totalBeforeTax) *
        Number(this.setData.numberOfUnits);
      this.setData.purchasePrice =
        Number(this.setData.purchasePrice) * Number(this.setData.numberOfUnits);
    },
    changgeShowEdit(val) {
      this.showEdit = val;
    },
    openImgDia() {
      this.$refs.manualimgupdia.open();
    },
    getImgUrl(data) {
      this.setData.voucherUrl = data;
    //   console.log(this.setData.voucherUrl)
    },
    //保存
    async saveManualOrder() {
      
   //   console.log(this.setData.voucherUrl)
      if (!this.setData.roomId) {
        this.$message.warning("请选择房间");
      } else {
        this.setData.hotelSonId = this.hotelId;
        this.setData.dailyRatesJosn.forEach((item)=>{
          item.date=this.initDate(item.date)
        })
        this.setData.purchasePriceJosn.forEach((item)=>{
          item.date=this.initDate(item.date)
        })
        this.setData.checkIn=this.initDate( this.setData.checkIn)
        this.setData.checkOut=this.initDate( this.setData.checkOut)
        this.setData.dailyRatesJosn=JSON.stringify(this.setData.dailyRatesJosn)
        this.setData.purchasePriceJosn=JSON.stringify(this.setData.purchasePriceJosn)

        let { code, data } = await saveManualOrder_api(this.setData);
        if (code == 0) {
           this.setData.dailyRatesJosn=JSON.parse(this.setData.dailyRatesJosn)
        this.setData.purchasePriceJosn=JSON.parse(this.setData.purchasePriceJosn)
          this.$message.success(data ? data : "提交成功");
        }
      }
    },
      //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    //回到订单
    goOrderPage() {
      this.$router.push({
        path: "/order",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.orderInfoHead {
  margin: top 20px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.searchBox {
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
.hotelListBox {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}
.hotelTitle {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.padding20 {
  padding-left: 20px;
  padding-right: 20px;
}
.roomBox {
  margin-bottom: 20px;
}
.roomTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}
.horseBox {
  margin-bottom: 10px;
  color: #666666;
}
.formLabel {
  margin-left: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.margin10 {
  padding-left: 10px;
  padding-right: 10px;
}
.roomPriceBox {
  width: 120px;
  height: 100px;
  border: 1px solid #e6eefa;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.priceTitle {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #e7eef4;
}
.priceContent {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ee4d52;
}
.fontRule {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ee4d52;
  margin-right: 10px;
}
</style>>

